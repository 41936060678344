import { lazy } from "react";
import { MODULE_IDS } from "../../constants/enums";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { CUSTOMER_ROUTE } from "./Customer.constants";

// customer
const ManageCustomerList = Loadable(
  lazy(() =>
    import("./CustomersList").then(({ CustomersList }) => ({
      default: CustomersList,
    })),
  ),
);

const ManageCustomer = Loadable(
  lazy(() =>
    import("./ManageCustomer").then(({ ManageCustomer }) => ({
      default: ManageCustomer,
    })),
  ),
);

// customer

export const customerRoutes = [
  {
    path: CUSTOMER_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.COMPANY]}>
        <ManageCustomerList />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${CUSTOMER_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.COMPANY]}>
        <ManageCustomer isBillingCompany={0} />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${CUSTOMER_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.COMPANY]}>
        <ManageCustomer isBillingCompany={0} />
      </AuthorizedRoute>
    ),
  },
];
