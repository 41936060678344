import { Button, Grid, Stack } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  ILocationResponsePayload,
  LocationAutoComplete,
} from "../../../../components/LocationAutoComplete/LocationAutoComplete";
import { RightPanel } from "../../../../components/RightPanel";
import { TextFieldWithLabel } from "../../../../components/TextFieldWithLabel/TextFieldWithLabel";
import {
  CustomFormLabel,
  CustomSelectorWithLabel,
} from "../../../../components/formsComponents";
import { CustomDatePicker } from "../../../../components/formsComponents/CustomDatePicker";
import { error_message } from "../../../../constants/constants";
import { useDispatchWrapper } from "../../../../hooks";
import {
  ICustomerDispatchAddress,
  upsertCustomerDispatchAddressAsync,
} from "../../../../redux/customerDispatchAddress";
import moment from "moment";
import { useNavigate } from "react-router-dom";

interface IManageCustomerDispatchAddress {
  open: boolean;
  data: ICustomerDispatchAddress;
  onClose: () => void;
  onComplete: (data: ICustomerDispatchAddress) => void;
}
export const ManageCustomerDispatchAddress: React.FC<
  IManageCustomerDispatchAddress
> = (props) => {
  const { open, data, onClose, onComplete } = props;
  const isUpdate = data?.customer_dispatch_address_uuid ? true : false;
  const navigate = useNavigate();

  const dispatch = useDispatchWrapper();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: data,
    validate: (values) => {
      const errors: any = {};
      if (!values.customer_dispatch_address_line2) {
        errors.customer_dispatch_address_line2 = error_message.required;
      }
      if (!values.customer_dispatch_address_city) {
        errors.customer_dispatch_address_city = error_message.required;
      }
      if (!values.customer_dispatch_address_state) {
        errors.customer_dispatch_address_state = error_message.required;
      }
      return errors;
    },
    onSubmit: async (values) => {
      dispatch(
        upsertCustomerDispatchAddressAsync(
          {
            ...values,
            customer_name: values.customer_name,
            customer_uuid: values.customer_uuid,
          },
          (isSuccess, customerDispatchAddress) => {
            if (isSuccess && customerDispatchAddress) {
              onComplete(customerDispatchAddress);
            }
          },
        ),
      );
    },
  });

  const handleLocation = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      customer_dispatch_address_line2: data.address,
      customer_dispatch_address_city: data.city,
      customer_dispatch_address_state: data.state,
      customer_dispatch_address_country: data.country,
      customer_dispatch_address_pincode: data.postalCode,
    });
  };

  return (
    <RightPanel
      open={open}
      heading={isUpdate ? "Update Dispatch Address" : "Create Dispatch Address"}
      subHeading={`Company: ${values.customer_name}`}
      width="50%"
      isWrappedWithForm
      onFormSubmit={handleSubmit}
      actionButtons={() => (
        <Stack direction={"row"} spacing={2}>
          <Button variant="contained" type="button" onClick={onClose}>
            Close
          </Button>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </Stack>
      )}
    >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading="Dispatch Name"
              id="dispatch_name"
              fullWidth
              value={values.dispatch_name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Add Location</CustomFormLabel>
            <LocationAutoComplete
              value={values.customer_dispatch_address_line2}
              onLocationChange={handleLocation}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Address line 1"}
              type="text"
              id="customer_dispatch_address_line1"
              fullWidth
              value={values.customer_dispatch_address_line1}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Address line 2"}
              type="text"
              id="customer_dispatch_address_line2"
              fullWidth
              value={values.customer_dispatch_address_line2}
              onChange={handleChange}
              error={errors.customer_dispatch_address_line2 ? true : false}
              helperText={errors.customer_dispatch_address_line2}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"City"}
              type="text"
              id="customer_dispatch_address_city"
              fullWidth
              value={values.customer_dispatch_address_city}
              onChange={handleChange}
              error={errors.customer_dispatch_address_city ? true : false}
              helperText={errors.customer_dispatch_address_line2}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"State"}
              type="text"
              id="customer_dispatch_address_state"
              fullWidth
              value={values.customer_dispatch_address_state}
              onChange={handleChange}
              error={errors.customer_dispatch_address_state ? true : false}
              helperText={errors.customer_dispatch_address_line2}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Pin Code"}
              type="text"
              id="customer_dispatch_address_pincode"
              fullWidth
              value={values.customer_dispatch_address_pincode}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Country"}
              type="text"
              id="customer_dispatch_address_country"
              fullWidth
              value={values.customer_dispatch_address_country}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={6} lg={4}>
            <TextFieldWithLabel
              heading="Dispatch Through"
              id="dispatch_through"
              fullWidth
              value={values.dispatch_through}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextFieldWithLabel
              heading="Transport ID"
              id="transport_id"
              fullWidth
              value={values.transport_id}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextFieldWithLabel
              heading="Trans.Doc NO"
              id="transport_doc_no"
              fullWidth
              value={values.transport_doc_no}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomFormLabel>Trans.Doc Date</CustomFormLabel>
            <CustomDatePicker
              fullWidth
              value={values.transport_doc_date || ""}
              onChange={(date) => {
                const newDate = moment(date).format("YYYY-MM-DD");
                setFieldValue("transport_doc_date", newDate);
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextFieldWithLabel
              heading={"Vehicle No"}
              type="text"
              id="vehicle_no"
              fullWidth
              value={values.vehicle_no}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </form>
    </RightPanel>
  );
};
