import React from "react";

import {
  IJobCustomerBranch,
  initialJobCustomerBranch,
} from "../../../redux/customerBranch";
import { IJobCustomer, defaultCustomer } from "../../../redux/job_customer";

export const LOCAL_KEY_BILLING_INFO = "billing_info";

export interface IUserBillingInfo {
  customer: IJobCustomer;
  customer_branch: IJobCustomerBranch;
}

export const saveUserBillingInfoInLocalStore = (
  customer_info: IJobCustomer | null,
  branch_info: IJobCustomerBranch | null,
) => {
  let localData = localStorage.getItem(LOCAL_KEY_BILLING_INFO);
  if (localData) {
    let existing_billing_info = JSON.parse(localData) as IUserBillingInfo;
    if (customer_info) {
      existing_billing_info.customer = customer_info;
    }
    if (branch_info) {
      existing_billing_info.customer_branch = branch_info;
    }
    localStorage.setItem(
      LOCAL_KEY_BILLING_INFO,
      JSON.stringify(existing_billing_info),
    );
  } else {
    const new_billing_info: IUserBillingInfo = {
      customer: customer_info ? customer_info : defaultCustomer,
      customer_branch: branch_info ? branch_info : initialJobCustomerBranch,
    };
    localStorage.setItem(
      LOCAL_KEY_BILLING_INFO,
      JSON.stringify(new_billing_info),
    );
  }
  window.location.reload();
};

export const useBillingInfoFromLocalStore = (): IUserBillingInfo => {
  const [billing_info] = React.useState<IUserBillingInfo>(() => {
    const existing_billing_info = localStorage.getItem(LOCAL_KEY_BILLING_INFO);
    if (existing_billing_info) return JSON.parse(existing_billing_info);
    return {
      customer: defaultCustomer,
      customer_branch: initialJobCustomerBranch,
    };
  });

  return billing_info;
};
