import { Grid } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  RoleAutoSearch,
  RoleIdAutoSearch,
} from "../../../components/AutoCompleteSearches/RoleIdAutoSearch";
import { UserBranchAutoSearch } from "../../../components/AutoCompleteSearches/UserBranchAutoSearch";
import { Dialog } from "../../../components/Dialogs/Dialog";
import LoadingDialog from "../../../components/Dialogs/LoadingDialog";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../components/formsComponents";
import { renderDropdownValue } from "../../../helpers";
import { useDispatchWrapper } from "../../../hooks";
import { IUserCreation } from "../../../redux/UserProfileList/userProfile.types";
import { createNewUserAsync } from "../../../redux/UserProfileList/userProfileActions";
import { IAddNewUserProps } from "./AddNewUser.types";
import { ErrorMessage } from "../../../components/ErrorMessage/ErrorMessage";
import { UsersAutoSearch } from "../../../components/AutoCompleteSearches/UsersAutoSearch";

const INITIAL_STATE: IUserCreation = {
  first_name: "",
  last_name: "",
  email: "",
  user_password: "",
  confirmPassword: "",
  branch_uuid: "",
  branch_name: "",
  manager_uuid: "",
  manager_name: "",
  status: "ACTIVE",
  role_uuid: "",
  role_value: "",
};

export const AddQuickUserForm: React.FC<IAddNewUserProps> = (props) => {
  const { open, onClose } = props;

  const [saveLoading, setSaveLoading] = React.useState(false);

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();
  const {
    values,
    errors,
    setValues,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: { ...INITIAL_STATE, confirmPassword: "" },
    validate: (values) => {
      const errors: any = {};
      if (!values.first_name) {
        errors.first_name = "First name is required.";
      }
      if (!values.email) {
        errors.email = "Email is required.";
      }
      if (
        values.email !== "" &&
        !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
          values.email,
        )
      ) {
        errors.email = "*Invalid email address.";
      }
      // if (!values.manager_uuid) {
      //   errors.manager_uuid = "Password is required.";
      // }
      if (!values.user_password) {
        errors.user_password = "Manager is required.";
      }
      if (values.user_password.length < 6) {
        errors.user_password = "Password must be at least 6 characters long.";
      }
      if (!values.confirmPassword) {
        errors.confirmPassword = "Confirm password is required.";
      }
      if (values.user_password !== values.confirmPassword) {
        errors.confirmPassword = "Passwords are not matching.";
      }
      if (!values.role_uuid) {
        errors.role_uuid = "Role is required.";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        createNewUserAsync(
          {
            ...values,
          },
          (isSuccess, user_uuid) => {
            if (isSuccess && user_uuid) {
              navigate("/users/manage/" + user_uuid);
              onClose();
            }
            setSaveLoading(false);
          },
        ),
      );
    },
  });

  const handleUserCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputText = e.target.value;
    const alphaNumericText = inputText.replace(/[^A-Za-z0-9]/g, "");

    setFieldValue("user_code", alphaNumericText.toUpperCase());
  };

  return (
    <Dialog
      open={open}
      title="Create New Employee"
      onClose={onClose}
      size="md"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      actions={[
        { type: "button", label: "Close", variant: "text", onClick: onClose },
        {
          type: "submit",
          label: "Save",
          variant: "contained",
          disabled: saveLoading,
        },
      ]}
    >
      <Grid container spacing={1}>
        <LoadingDialog open={saveLoading} />
        <Grid item xs={12} lg={3}>
          <CustomFormLabel>First Name </CustomFormLabel>
          <CustomTextField
            name="first_name"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.first_name}
            error={errors.first_name ? true : false}
            helperText={errors.first_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <CustomFormLabel>Last Name </CustomFormLabel>
          <CustomTextField
            name="last_name"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.last_name}
            error={errors.last_name ? true : false}
            helperText={errors.last_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <CustomFormLabel>Email </CustomFormLabel>
          <CustomTextField
            name="email"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.email}
            error={errors.email ? true : false}
            helperText={errors.email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <CustomFormLabel>Password </CustomFormLabel>
          <CustomTextField
            name="user_password"
            variant="outlined"
            size="small"
            type="password"
            fullWidth
            value={values.user_password}
            error={errors.user_password ? true : false}
            helperText={errors.user_password}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <CustomFormLabel>Confirm Password</CustomFormLabel>
          <CustomTextField
            name="confirmPassword"
            variant="outlined"
            size="small"
            type="password"
            fullWidth
            value={values.confirmPassword}
            error={errors.confirmPassword ? true : false}
            helperText={errors.confirmPassword}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <UserBranchAutoSearch
            label="Branch"
            error={errors.branch_uuid}
            value={{
              branch_uuid: values.branch_uuid,
              branch_name: values.branch_name,
            }}
            onSelect={(value) => {
              setValues({
                ...values,
                branch_uuid: value.branch_uuid as string,
                branch_name: value.branch_name as string,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomFormLabel>Role Name</CustomFormLabel>
          <RoleAutoSearch
            value={values.role_uuid}
            onSelect={(newValue) => {
              setValues({
                ...values,
                role_uuid: newValue.role_uuid as any,
                role_value: newValue.role_value as string,
              });
            }}
          />
          {errors.role_uuid && <ErrorMessage value={errors.role_uuid} />}
        </Grid>
        {values.role_value.toUpperCase() !== "ADMIN" && (
          <Grid item xs={12} md={3}>
            <Grid item xs={12} lg={12}>
              <UsersAutoSearch
                label="Select Manager"
                value={{
                  first_name: values.manager_name as string,
                  user_uuid: values.manager_uuid as string,
                }}
                onSelect={(newValue) => {
                  setValues({
                    ...values,
                    manager_name:
                      newValue.first_name + " " + (newValue.last_name ?? ""),
                    manager_uuid: newValue.user_uuid,
                  });
                }}
                disabled={!values.role_value}
                error={errors.manager_uuid}
                roleGroup={"MANAGER"}
                // disabled={(isUpdate && values.project_name) ? true : false}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};
