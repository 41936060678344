import produce from "immer";
import {
  CLEAR_EXPENSE,
  CLEAR_EXPENSE_CATEGORY,
  CLEAR_EXPENSE_REPORT,
  CLEAR_EXPENSE_STATE,
  ExpenseModuleActions,
  FETCH_EXPENSE_CATEGORY_FAILED,
  FETCH_EXPENSE_CATEGORY_LIST_FAILED,
  FETCH_EXPENSE_CATEGORY_LIST_PROGRESS,
  FETCH_EXPENSE_CATEGORY_LIST_SUCCESS,
  FETCH_EXPENSE_CATEGORY_PROGRESS,
  FETCH_EXPENSE_CATEGORY_SUCCESS,
  FETCH_EXPENSE_FAILED,
  FETCH_EXPENSE_LIST_FAILED,
  FETCH_EXPENSE_LIST_PROGRESS,
  FETCH_EXPENSE_LIST_SUCCESS,
  FETCH_EXPENSE_PROGRESS,
  FETCH_EXPENSE_REPORT_FAILED,
  FETCH_APPROVAL_EXPENSE_LIST_PROGRESS,
  FETCH_APPROVAL_EXPENSE_LIST_SUCCESS,
  FETCH_APPROVAL_EXPENSE_LIST_FAILED,
  FETCH_EXPENSE_REPORT_PROGRESS,
  FETCH_EXPENSE_REPORT_SUCCESS,
  FETCH_EXPENSE_SUCCESS,
  CLEAR_EXPENSE_LIST_STATE,
  CREATE_ADVANCE_EXPENSE_STATE,
  FETCH_EXPENSE_CAT_APPROVAL_LIST_PROGRESS,
  FETCH_EXPENSE_CAT_APPROVAL_LIST_FAILED,
  FETCH_EXPENSE_CAT_APPROVAL_LIST_SUCCESS,
  FETCH_CAT_APPROVAL_EXPENSE_LIST_PROGRESS,
  FETCH_CAT_APPROVAL_EXPENSE_LIST_SUCCESS,
  FETCH_CAT_APPROVAL_EXPENSE_LIST_FAILED,
} from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import {
  defaultExpense,
  defaultExpenseCategory,
  defaultExpenseModuleState,
  defaultExpenseApproval,
} from "./defaultState";

export const expenseModuleReducer = (
  state: IStoreState["expenseModule"] = defaultExpenseModuleState,
  action: ExpenseModuleActions,
) => {
  switch (action.type) {
    case FETCH_EXPENSE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.expenseList.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_EXPENSE_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.expenseList.loading = LoadState.Loaded;
        draftState.expenseList.list = data;
        draftState.expenseList.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_EXPENSE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.expenseList.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_EXPENSE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.expenseData.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_EXPENSE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.expenseData.loading = LoadState.Loaded;
        draftState.expenseData.data = data;
      });
      return newState;
    }
    case FETCH_EXPENSE_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.expenseData.loading = LoadState.Failed;
        draftState.expenseData.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_EXPENSE: {
      const newState = produce(state, (draftState) => {
        draftState.expenseData.loading = LoadState.NotLoaded;
        draftState.expenseData.data = defaultExpense;
      });
      return newState;
    }

    // expense category
    case FETCH_EXPENSE_CATEGORY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.expenseCategoryList.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_EXPENSE_CATEGORY_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.expenseCategoryList.loading = LoadState.Loaded;
        draftState.expenseCategoryList.list = data;
        draftState.expenseCategoryList.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_EXPENSE_CATEGORY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.expenseCategoryList.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_EXPENSE_CATEGORY_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.expenseCategoryData.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_EXPENSE_CATEGORY_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.expenseCategoryData.loading = LoadState.Loaded;
        draftState.expenseCategoryData.data = data;
      });
      return newState;
    }
    case FETCH_EXPENSE_CATEGORY_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.expenseCategoryData.loading = LoadState.Failed;
        draftState.expenseCategoryData.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_EXPENSE_CATEGORY: {
      const newState = produce(state, (draftState) => {
        draftState.expenseCategoryData.loading = LoadState.NotLoaded;
        draftState.expenseCategoryData.data = defaultExpenseCategory;
      });
      return newState;
    }
    // expense report
    case FETCH_APPROVAL_EXPENSE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.expenseApprovalList.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_APPROVAL_EXPENSE_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.expenseApprovalList.loading = LoadState.Loaded;
        draftState.expenseApprovalList.list = data;
        draftState.expenseApprovalList.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_APPROVAL_EXPENSE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.expenseApprovalList.loading = LoadState.Failed;
      });
      return newState;
    }

    // case FETCH_APPROVAL_EXPENSE_PROGRESS: {
    // 	const newState = produce(state, (draftState) => {
    // 		draftState.expenseApprovalData.loading = LoadState.InProgress;
    // 	});
    // 	return newState;
    // }
    // case FETCH_APPROVAL_EXPENSE_SUCCESS: {
    // 	const { data } = action.payload;
    // 	const newState = produce(state, (draftState) => {
    // 		draftState.expenseApprovalData.loading = LoadState.Loaded;
    // 		draftState.expenseApprovalData.data = data;
    // 	});
    // 	return newState;
    // }
    // case FETCH_APPROVAL_EXPENSE_FAILED: {
    // 	const { errorMessage } = action.payload;
    // 	const newState = produce(state, (draftState) => {
    // 		draftState.expenseApprovalData.loading = LoadState.Failed;
    // 		draftState.expenseApprovalData.error = errorMessage;
    // 	});
    // 	return newState;
    // }

    case FETCH_EXPENSE_CAT_APPROVAL_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.expenseCatApproval.loading = LoadState.InProgress;
        draftState.expenseCatApproval.list = [];
      });
      return newState;
    }
    case FETCH_EXPENSE_CAT_APPROVAL_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.expenseCatApproval.loading = LoadState.Loaded;
        draftState.expenseCatApproval.list = data;
        draftState.expenseCatApproval.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_EXPENSE_CAT_APPROVAL_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.expenseCatApproval.loading = LoadState.Failed;
        draftState.expenseCatApproval.list = [];
      });
      return newState;
    }

    case CLEAR_EXPENSE_REPORT: {
      const newState = produce(state, (draftState) => {
        draftState.expenseApprovalData.loading = LoadState.NotLoaded;
        draftState.expenseApprovalData.data = defaultExpenseApproval;
      });
      return newState;
    }
    case CLEAR_EXPENSE_LIST_STATE: {
      const newState = produce(state, (draftState) => {
        draftState.expenseList.loading = LoadState.NotLoaded;
        draftState.expenseList.list = [];
      });
      return newState;
    }
    case CREATE_ADVANCE_EXPENSE_STATE: {
      const newState = produce(state, (draftState) => {
        draftState.expenseData.loading = LoadState.NotLoaded;
        draftState.expenseData.data = {
          ...defaultExpense,
          expense_type: "ADVANCE",
          expense_date: new Date().toISOString(),
        };
      });
      return newState;
    }

    case FETCH_CAT_APPROVAL_EXPENSE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.expenseCatApprovalList.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CAT_APPROVAL_EXPENSE_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.expenseCatApprovalList.loading = LoadState.Loaded;
        draftState.expenseCatApprovalList.list = data;
        draftState.expenseCatApprovalList.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_CAT_APPROVAL_EXPENSE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.expenseCatApprovalList.loading = LoadState.Failed;
      });
      return newState;
    }

    // common state clear
    case CLEAR_EXPENSE_STATE: {
      return defaultExpenseModuleState;
    }
    default: {
      return state;
    }
  }
};
