import { lazy } from "react";
import { MODULE_IDS } from "../../constants/enums";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { CONTACTS_ROUTE } from "./constacts.constants";

// customer
const ManageContactsList = Loadable(
  lazy(() =>
    import("./ContactsList").then(({ ContactsList }) => ({
      default: ContactsList,
    })),
  ),
);

const ManageContacts = Loadable(
  lazy(() =>
    import("./ManageContact").then(({ ManageContact }) => ({
      default: ManageContact,
    })),
  ),
);

// customer

export const contactsRoutes = [
  {
    path: CONTACTS_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.CONTACTS]}>
        <ManageContactsList />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${CONTACTS_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.CONTACTS]}>
        <ManageContacts />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${CONTACTS_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.CONTACTS]}>
        <ManageContacts />
      </AuthorizedRoute>
    ),
  },
];
